<template>
  <div class="authWrapper">
    <div class="container">
      <div class="row whiteBG-wrap">
        <div class="col-lg-6 col-md-6 no-padding">
          <div class="left-bg-wrapper text-center">
            <h1>Forgot your Password?</h1>
            <p>
              Don't Worry! we've got your back. Just follow the instructions and
              you'll be good :)
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="auth-form-wrap form-reset">
            <div class="auth-form">
              <h2>Reset Password</h2>
              <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                <form @submit.stop.prevent="handleSubmit(sendOtpCode)">
                  <input-text
                    :rules="{ required: true, email: true }"
                    labelFor="username"
                    labelName="Username"
                    inputName="username"
                    formGroupClass="form-group mb-3"
                    placeholder="Enter your username"
                    :vmodel.sync="form.username"
                  />
                  <div class="form-group mb-4">
                    <button type="submit" class="submit-btn">
                      <b-spinner v-if="loginLoader" small></b-spinner>
                      Next
                    </button>
                  </div>
                </form>
              </ValidationObserver>
              <router-link to="/login" class="pw-reset-btn"
                >Back</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import helper from "@/resource/Helper";
export default {
  name: "ForgotPassword",
  data() {
    return {
      form: {
        username: "",
      },
      loginLoader: false,
      formErrorFlag: false,
      formErrors: [],
    };
  },
  methods: {
    sendOtpCode() {
      this.loginLoader = true;
      let app = this;
      let url = this.$endpoint.API_SEND_FORGOT_PASSWORD_OTP;
      axios
        .post(url, {
          data: this.form.username,
          option: "email",
        })
        .then(function(response) {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
            app.$store.commit("setRepEmail", app.form.username);
            helper.delayRedirect("PasswordOtpVerification", 1);
          }
        })
        .catch(function(error) {
          if (error.response.data.status == "error") {
            helper.infoMessage(
              app,
              error.response.data.status,
              error.response.data.message
            );
          }
          app.loginLoader = false;
        });
    },
  },
};
</script>
<style src="@/assets/css/authen.css" scoped></style>
<style>
#label-username,
#label-password {
  font-size: 16px !important;
  color: #909090 !important;
  margin-bottom: 10px !important;
}
#username,
#password {
  font-size: 14px !important;
  padding: 12px 15px !important;
}
</style>
